<template>
  <!--justify-space-around row wrap-->
  <v-container fluid>
    <v-row v-for="(row, ri) in actions.rows" :key="'r1'+ri">
      <v-col v-for="(c, ci) in row.components" :cols="12" :md="12 / row.components.length" :key="ci">
        <component
          :is="c.component"
          :action="c.action"
          :action-result="c.actionResult"
        />
      </v-col>
      <!--<div>{{dashboards}}</div>-->
    </v-row>
    <v-row v-for="i in actions.loading ? [1,2,3] : []" :key="'r2'+i">
      <v-col v-for="i in [1,2,3]" :key="i">
        <v-skeleton-loader
          type="card" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {mapGetters} from 'vuex'
import axios from 'axios'
import CardView from './card-view.vue'
const ComponentByType = {
  card: CardView
}

const makeViewComponent = (action, actionResult) => actionResult && {action, actionResult, component: ComponentByType[actionResult.type]}

export default {
  computed: {
    ...mapGetters(['api', 'activeMarketId'])
  },
  asyncComputed: {
    actions: {
      async get () {
        let allActions = await this.api.actions.getActions({cached: true})
        let dashboardActions = allActions.filter(({type}) => type === 'dashboard')

        let dashboardActionResults = await Promise.all(
          dashboardActions
            .filter(({url}) => url)
            .map(action => {
              let {url} = action
              return axios.get(url, {
                params: {
                  marketId: this.activeMarketId
                }
              })
              .then(({data}) => ({
                action,
                data
              }))
            }))

          let rows = dashboardActionResults
            .map(({action, data: {rows = []}}) => ({
              action, rows
            }))
            .reduce((l, {action, rows}) => l.concat(rows.map(row => ({action, row}))), [])
            .filter(({row}) => row && row.length)
            .map(({action, row}) => ({
              action,
              components: row.map(actionResult => makeViewComponent(action, actionResult)).filter(vc => vc && vc.component)
            }))
            .filter(({components}) => components && components.length)
          return {
            loading: false,
            rows
          }
      },
      default: {
        loading: true,
        rows: []
      }
    }
  }
}
</script>
