<template>
  <dashboard></dashboard>
</template>
<script>
import Dashboard from './dashboard/dashboard.vue'
export default {
  components: {
    Dashboard
  }
}
</script>
