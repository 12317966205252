<template>
  <a :href="actionResult.link" target="_blank">
    <v-icon>fa-external-link-alt</v-icon>
  </a>
</template>
<script>
export default {
  props: ['action-result']
}
</script>
