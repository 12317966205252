<template>
  <v-btn
    icon
    :to="{
      name: 'dashboard-action-collection',
      params: {
        actionId: actionId,
        actionResultKey: actionResultKey
      }
    }"
    >
    <v-icon>fa-eye</v-icon>
  </v-btn>
</template>
<script>
export default {
  props: ['action', 'action-result'],
  data: () => ({
    dialog: false,
    loadTable: false
  }),
  computed: {
    actionId () {
      let {actionId} = this.action || {}
      return actionId
    },
    collectionId () {
      let {collectionId} = this.actionResult || {}
      return collectionId
    },
    actionResultKey () {
      let {key} = this.actionResult || {}
      return key
    }
  }
}
</script>
