<template>
  <v-card dark color="primary" tile ripple style="height: 100%">
    <v-card-title class="text-center d-block">
      <v-icon x-large>{{actionResult.icon}}</v-icon>
    </v-card-title>
    <v-card-title class="text-center d-block">
      {{actionResult.title}}
    </v-card-title>
    <v-card-text class="text-center d-block">
      {{actionResult.text}}
    </v-card-text>
    <v-card-actions v-if="actionComponent && !hideActions">
      <v-spacer />
      <component :is="actionComponent" :action="action" :action-result="actionResult" />
    </v-card-actions>
  </v-card>
</template>
<script>
import CardActionExternalLink from './card-action-external-link.vue'
import CardActionRouteLink from './card-action-route-link.vue'
import CardActionViewCollection from './card-action-view-collection.vue'
export default {
  props: ['action', 'action-result', 'hide-actions'],
  computed: {
    actionComponent () {
      let {data: {collectionId} = {}} = this.actionResult || {}
      if (collectionId /*&& Array.isArray(keys)*/) {
        return CardActionViewCollection
      }
      let {link, route} = this.actionResult || {}
      if (route && (typeof route === 'object')) {
        return CardActionRouteLink
      }
      if (link && (link.charAt(0) === '/')) {
        return CardActionRouteLink
      }
      if (link) {
        return CardActionExternalLink
      }
      return null
    }
  }
}
</script>
