<template>
  <v-btn icon :to="actionResult.route">
    <v-icon>fa-link</v-icon>
  </v-btn>
</template>
<script>
export default {
  props: ['action-result']
}
</script>
