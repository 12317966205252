<template>
  <layout-page>
    <v-layout>
      <v-container>
        <custom-dashboard-view />
      </v-container>
    </v-layout>
  </layout-page>
</template>
<script>
import {mapGetters} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'
import CustomDashboardView from './components/custom-dashboard-view.vue'
export default {
  components: {
    LayoutPage,
    CustomDashboardView
  },
  computed: {
    ...mapGetters(['translate', 'api', 'collectionsConfig', 'relationsConfig', 'reverseRelationsConfig', 'channelsConfig']),
    collectionChartData () {
      const collections = this.stats.collections || {}
      const fakeCollectionSchemas = Object.keys(collections).map(collectionId => ({collectionId}))
      return this.collectionsConfig.mapCollectionSchemas(fakeCollectionSchemas)
        .map(({collectionId, label}) => ([
          label, (collections[collectionId] || {}).count
        ]))
    },
    relationChartData () {
      const relations = this.stats.relations || {}
      const fakeRelationObjects = Object.keys(relations).map(relationId => ({relationId}))
      return this.relationsConfig.mapRelations(fakeRelationObjects)
        .filter(({relationId}) => relations[relationId])
        .map(({relationId, label}) => ([
          `${label} (${this.collectionsConfig.getCollectionLabel(relations[relationId].sourceCollectionId)})`,
          relations[relationId].count
        ]))
    },
    reverseRelationChartData () {
      const reverseRelations = this.stats.reverseRelations || {}
      const fakeRelationObjects = Object.keys(reverseRelations).map(relationId => ({relationId}))
      return this.reverseRelationsConfig.mapReverseRelations(fakeRelationObjects)
        .filter(({relationId}) => reverseRelations[relationId])
        .map(({relationId, label}) => ([
          `${label} (${this.collectionsConfig.getCollectionLabel(reverseRelations[relationId].targetCollectionId)})`,
          reverseRelations[relationId].count
        ]))
    },
    channelsChartDataGrouped () {
      const channels = this.stats.channels
      const fakeChannelObjects = Object.keys(channels).map(channelId => ({channelId}))
      return this.channelsConfig.mapChannels(fakeChannelObjects)
        .map(({channelId, label}) => ({
          channelId,
          label,
          chartData: channels[channelId]
            .filter(({count}) => count > 0)
            .map(({completeness, count}) => [`${Math.round(completeness * 100)}%`, count])
        }))
    },
    rolesChartData () {
      const roles = this.stats.roles || {}
      return Object.entries(roles)
    }
  },
  asyncComputed: {
    stats: {
      async get () {
        return this.api.getStats()
      },
      default: {
        loading: true,
        collections: {},
        relations: {},
        reverseRelations: {},
        users: {},
        roles: {},
        channels: {}
      }
    }
  }
}
</script>
